import { useRef, useMemo, useEffect } from 'react';

import Head from 'next/head';
import dynamic from 'next/dynamic';

import { cabinClassNames } from 'font';

import { templates } from 'templates';

import RouteProgress from 'libs/router/RouteProgress';
import { keys } from 'libs/object/object';
import { clearTimeoutIfExists } from 'libs/node';

import { Alternative } from 'stores/intl/ints.types';

import { LeadProvider } from 'context/LeadContext';
import { ConsentProvider } from 'context/ConsentContext';
import { ContentProvider, initialValues as initialContentValues } from 'context/ContentContext';

import { useIsIframe } from 'hooks/useIsIframe';
import { useEventListener } from 'hooks/useEventListener';

import { CustomError } from 'ui/organisms/CustomError';
import { OverleadWidget } from 'ui/atoms/OverleadWidget';
import { DynamicImport } from 'ui/DynamicImport';

import { FooterLoader } from 'features/layout/organisms/Footer/FooterLoader';
import Header from 'features/layout/organisms/Header/Header';
import Topbar from 'features/layout/organisms/Topbar/Topbar';
import { Layout, LayoutContainer, LayoutContent } from 'features/layout/organisms/Layout';

// const Consent = dynamic(() => import('features/layout/organisms/Consent/Consent'), { ssr: false });

const alignURLPattern = (item: Alternative) => ({
  ...item,
  link: item.link === '' ? '/' : item.link,
});

type Props = {
  statusCode: number;
};

export const Page = ({ statusCode, ...props }: Props & typeof initialContentValues): JSX.Element => {
  const { isIframe } = useIsIframe();
  const scrollThrottle = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      clearTimeoutIfExists(scrollThrottle.current);
    };
  }, []);

  useEventListener('scroll', () => {
    if (scrollThrottle.current) {
      return;
    }

    scrollThrottle.current = setTimeout(() => {
      window.requestAnimationFrame(() => {
        scrollThrottle.current = undefined;

        if (!window) {
          return;
        }

        const header = document.getElementById('header');
        if (header) {
          header.classList[window.scrollY >= 35 ? 'add' : 'remove']('fixed');
        }
      });
    }, 500);
  });

  const alternates = useMemo(() => {
    if (Array.isArray(props.links) === false) {
      return [];
    }

    const links = props.links.map(alignURLPattern);

    if (links.length === 0 || Number(props.page) !== 1) {
      return [
        alignURLPattern({
          key: props.lang,
          link: props.pathname,
        }),
      ];
    }

    if (links.find((i) => i.link === props.pathname) === undefined) {
      /**
       * For rewritten routes in next.config.js files
       * TBD: Potencially can be hardcoded so we get alternates even for this case
       * */
      return [];
    }

    return links;
  }, [props.page, props.links, props.pathname]);

  const renderTemplate = useMemo(() => {
    if (props.data?.page_type in templates) {
      const Template = templates[props.data.page_type as keyof typeof templates];
      return <Template />;
    }
  }, [props.data?.page_type]);

  if ([404, 500].includes(statusCode)) {
    return <CustomError statusCode={statusCode} />;
  }

  return (
    <>
      <RouteProgress />

      {process.env.TRACKING_DISABLED !== 'true' && process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID && <OverleadWidget />}

      <main data-page-type="ssr" className={cabinClassNames}>
        <Head>
          <meta name="viewport" content="width=device-width" />

          {alternates.map((i) => (
            <link key={i.key} rel="alternate" hrefLang={i.key} href={`${process.env.HOST || ''}${i.link || ''}`} />
          ))}

          {props.data.not_indexed ? <meta name="robots" content="noindex, nofollow" /> : null}

          {props.data.document_title || props.data.title ? (
            <>
              <meta property="og:title" content={props.data.document_title || props.data.title} />
              <title>{props.data.document_title || props.data.title}</title>
            </>
          ) : null}

          {props.data.meta_description && (
            <>
              <meta name="description" content={props.data.meta_description} />
              <meta property="og:description" content={props.data.meta_description} />
            </>
          )}

          {props.data.meta_background?.url && <meta property="og:image" content={props.data.meta_background.url} />}
        </Head>

        <LeadProvider>
          <ConsentProvider>
            <ContentProvider
              {...keys(initialContentValues).reduce(
                (acc, current) => ({ ...acc, [current]: props[current] }),
                {} as typeof initialContentValues,
              )}
            >
              {/* <Consent /> */}

              <Layout className={props.data.page_type}>
                <LayoutContainer>
                  {!isIframe && (
                    <>
                      <Topbar />

                      <Header />
                    </>
                  )}

                  <LayoutContent>{renderTemplate}</LayoutContent>
                </LayoutContainer>

                {!isIframe && (
                  <DynamicImport fallback={() => <FooterLoader />}>
                    {() => {
                      const Component = dynamic(() => import('features/layout/organisms/Footer/Footer'), {
                        ssr: false,
                        loading: () => <FooterLoader />,
                      });

                      return <Component />;
                    }}
                  </DynamicImport>
                )}
              </Layout>
            </ContentProvider>
          </ConsentProvider>
        </LeadProvider>
      </main>
    </>
  );
};
