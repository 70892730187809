/* eslint react-hooks/exhaustive-deps: "warn" */

import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';

import { useRouter } from 'libs/router/useRouter';

import { LoaderSpin } from 'ui/atoms/LoaderSpin/LoaderSpin';

import { FormHubspotStringCss } from 'features/customers/organisms/FormHubspot/FormHubspotStringCss';

import styles from './FormHubspot.module.scss';

type HubspotType = {
  forms: {
    create: (options: {
      region: string;
      portalId: string;
      formId: string;
      target: string;
      css?: string;
      onFormReady: () => void;
    }) => void;
  };
};

export type FormHubspotProps = {
  data: Record<string, string> & { default: string };
};

export const FormHubspot = ({ data }: FormHubspotProps) => {
  const { query } = useRouter();
  const hbsptRef = useRef<HubspotType | null>(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeForm = () => {
      if (!hbsptRef.current) {
        return;
      }

      const lang = query.lang as string;
      const formId = lang in data ? data[lang] : data.default;

      hbsptRef.current.forms.create({
        region: 'eu1',
        portalId: '144159233',
        formId,
        target: '#hubspotForm',
        css: FormHubspotStringCss,
        onFormReady: () => {
          setLoading(false);
        },
      });
    };

    if (!hbsptRef.current) {
      const script = document.createElement('script');

      script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
      script.async = true;
      script.onload = () => {
        hbsptRef.current = (window as unknown as { hbspt?: HubspotType }).hbspt ?? null;
        initializeForm();
      };

      document.body.appendChild(script);
    } else {
      initializeForm();
    }

    return () => {
      const formWrapper = document.getElementById('hubspotForm');

      if (formWrapper) {
        formWrapper.innerHTML = '';
      }
    };
  }, [data, query.lang]);

  return (
    <>
      <div className={cn(styles['hubspot-form-loading'], !loading && styles['hidden'])}>
        <LoaderSpin />
      </div>

      <div className={cn(styles['hubspot-form-wrapper'], loading && styles['hidden'])}>
        <div id="hubspotForm" className={styles['hubspot-form']} />
      </div>
    </>
  );
};
